import React from "react";

const OTC = () => {
    return(
        <>
        <h1>Over The Counter Drugs</h1>
        </>
    )
}

export default OTC;