import React from "react";
import COOP from '../../../../assets/images/Convenient.webp';
import ECS from '../../../../assets/images/customer-support.webp';
import Shipping from '../../../../assets/images/shipping.webp';
import AP from '../../../../assets/images/Affordable-pricing.webp';

const AboutPharmacy = () => {
    return(
        <>
        <section className="about-pharmacy-sec spacer">
  <div className="container">
    <div className="row">
	  <div className="col-md-6">
	    <div className="about-pharmacy-box ap-box-1">
		<div className="about-pharmacy-img-wrp ap-img-box-1">
		  <figure role="none">
		    <img src={COOP} width="62" height="54" alt="Convenient Online Ordering Process" />
		  </figure>
		 </div>
		  <h3>Convenient Online Ordering Process</h3>
		  <p>Our online pharmacy offers a streamlined and user-friendly ordering process.</p>
		</div>
	  </div>
	  <div className="col-md-6">
	    <div className="about-pharmacy-box ap-box-2">
		<div className="about-pharmacy-img-wrp ap-img-box-2">
		  <figure role="none">
		    <img src={ECS} width="49" height="57" alt="Exceptional Customer Support" />
		  </figure>
		 </div>
		  <h3>Exceptional Customer Support</h3>
		  <p>Our dedicated support team is available to assist you with any inquiries or concerns.</p>
		</div>
	  </div>
	  <div className="col-md-6">
	    <div className="about-pharmacy-box ap-box-3">
		<div className="about-pharmacy-img-wrp ap-img-box-3">
		  <figure role="none">
		    <img src={Shipping} width="63" height="50" alt="Exceptional Customer Support" />
		  </figure>
		 </div>
		  <h3>Exceptional Customer Support</h3>
		  <p>Our dedicated support team is available to assist you with any inquiries or concerns.</p>
		</div>
	  </div>
	  <div className="col-md-6">
	    <div className="about-pharmacy-box ap-box-4">
		<div className="about-pharmacy-img-wrp ap-img-box-4">
		  <figure role="none">
		    <img src={AP} width="56" height="43" alt="Affordable pricing" />
		  </figure>
		 </div>
		  <h3>Affordable pricing</h3>
		  <p>We offer high-quality prescription medications at affordable prices, easing your financial burden.</p>
		</div>
	  </div>
	</div>
  </div>
</section>
        </>
    )
}

export default AboutPharmacy;