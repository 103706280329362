import React from "react";
import './page_404.css';
import {Link} from 'react-router-dom';
const Page404 = () => {
    return(
        <>
        <div className="page404-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-404-inner-wrp">
                            <h1>Page Not Found</h1>
                            <p>Oops! The page you’re looking for doesn’t exist.</p>
                            <p>We’re sorry, but the page you’re trying to access is either moved, renamed, or doesn’t exist anymore.</p>
                            <p>Here are a few things you can try:</p>
                            <ul>
                                <li>
                                    <p><strong>Check the URL:</strong> Ensure the web address is spelled correctly.</p>
                                </li>
                                <li>
                                    <p><strong>Go back to the homepage:</strong> <Link to="/">Click here</Link> to return to our homepage.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Page404;