import React from "react";

const SearchPrescription = () => {
    return(
        <>
        <div class="search-prescription-sec spacer">
  <div class="container">
    <div class="row">
	  <div class="col-md-12">
	    <div class="search-prescription-wrp">
		  <h1>Search Prescription Drugs from Canada</h1>
		  <section>
		  <p class="search-prescr-content">At Online Canadian Pharmacy, we find the lowest prescription drug prices for you.</p>
		  <div class="search-prescription-box">
            <form>
                <input type="text" placeholder="Search you Prescription.." />
            </form>
			<p>* The price of shipping is USD 9.95 within North America. Additional charges may apply for overweight packages.</p>
		  </div>
		  </section>
		</div>
	  </div>
	</div>
  </div>
</div>
        </>
    )
}

export default SearchPrescription;