import React from "react";

const Tab = () => {
    return(
        <>
        <h1>Tab</h1>
        </>
    )
}

export default Tab;