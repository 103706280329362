import React from "react";
import FooterMenu from './Components/FooterMenu';
import CopyRight from './Components/Copyright';
import './Footer.css';
const Footer = () => {
    return(
        <>
        <FooterMenu />
        <CopyRight />
        </>
    )
}

export default Footer;