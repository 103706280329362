import React from 'react';
import './Header.css';
import Topbar from './Components/Topbar';
import HeaderMenu from './Components/HeaderMenu';


const Header = () => {
    return(
        <>
        <Topbar />
        <HeaderMenu />
        </>
    )
}

export default Header;