import React from "react";

const PreferredPharmacy = () => {
    return(
        <>
<section class="preferred-pharmacy-sec spacer">
  <div class="container">
    <div class="row">
	  <div class="col-md-6">
	    <div class="pp-title-wrp">
		  <h2>Why choose Online Canadian Pharmacy as your preferred online pharmacy?</h2>
		</div>
	  </div>
	  <div class="col-md-6">
	    <div class="pp-cont-wrp">
		  <p>If you are looking to buy your prescription drugs from Canada or globally, through a reputable international online pharmacy, Online Canadian Pharmacy provides you access to a trusted source of affordable and safe prescription drugs.</p>
		  <ul>
		    <li>We dispense prescription drugs through licensed pharmacies</li>
			<li>We request for a valid prescription</li>
			<li>We display the company address and phone number</li>
			<li>Your personal health information is kept confidential</li>
			<li>Secured payments</li>
			<li>We require patient agreement</li>
		  </ul>
		</div>
	  </div>
	</div>
  </div>
</section>
        </>
    )
}

export default PreferredPharmacy;