import React, { useState } from "react";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to toggle accordion
  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // Data for the accordion
  const accordionData = [
    {
      title: "Our History",
      content: `Online Canadian Pharmacy is a mail order pharmacy, founded in 2002 by Dawn Polley, and is still owned and operated by Ms. Polley, a Canadian Licensed Pharmacist, to this day. Ms Polley has long established relationships with pharmacies in Canada and international pharmacy fulfillment centers, that are operated by pharmacists, and are licensed to dispense prescription medication in the jurisdictions that they are located.`,
    },
    {
      title: "Mission and Values",
      content: `We strive to offer our customers the lowest possible prices, so we have not only partnered with a local Canadian Pharmacy, but also licensed pharmacies operating in other countries. These pharmacy choices allow our customers as much choice and access to lower prices as possible in purchasing prescription medications. It's all about customer choice, so we provide you with complete information every step of the way. Safety, customer service and low prices are the three pillars that are fundamental to our business.`,
    },
    {
      title: "Our Team",
      content: `Dawn Polley has been a leader in the online Canadian pharmacy industry for over 15 years. Dawn is the past President of the Canadian International Pharmacy Association (CIPA). She is considered a pioneer online pharmacist, who was instrumental in setting up industry safety standards. She takes pride in the fact that Online Canadian Pharmacy is a certified, legal Canada pharmacy intermediary that offers patients the highest standards of patient care and customer service with tremendous cost savings for patients who buy prescription drugs online.`,
    },
    {
      title: "Our Facilities",
      content: `We are located just two hours north of Seattle in Surrey, British Columbia, Canada.`,
    },
    {
      title: "Certifications",
      content: `We are an established online pharmacy accredited by both the Canadian International Pharmacy Association (CIPA) and the International Pharmacy Association of British Columbia (IPABC).`,
    },
    {
      title: "Safety",
      content: `As a member of CIPA, we provide access to safe and affordable medications. We do not dispense controlled substances, or any drugs that are not suitable for distance-based medications dispensing. CIPA standards allow for up to a maximum 3-month supply. We provide the same quality controls and convenience as the U.S. mail-order services. It enables prescription buyers to maintain their health with brand-name pharmaceuticals delivered right to their homes. This makes CIPA and Online Canadian Pharmacy an immediate and trustworthy part of the solution to exceedingly high drug prices in the U.S.`,
    },
    {
      title: "Partnerships",
      content: `In addition to dispensing from a Canadian dispensing pharmacy, Online Canadian Pharmacy also dispenses your medications from international fulfillment centers that are approved by the regulatory bodies from their respective countries. The dispensaries we are affiliated with are in the following jurisdictions that ship product to online customers: Canada, Mauritius, New Zealand, Australia, Turkey, United Kingdom, India, and the United States. This allows us to provide you with a range of options and affordable prices.`,
    },
  ];

  return (
    <section className="who-we-are-sec spacer curve_bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-wrp">
              <h2>Who We Are</h2>
              <p>
                Our online pharmacy gives you access to the lowest prices for
                your online prescription drugs. We offer both brand name drugs
                and generic drugs at prices that are generally far lower than
                those in the United States.
              </p>
              <div className="learn-more-wrp pt-4">
                <a
                  href="/about-us/"
                  aria-label="Learn More"
                  contentEditable="false"
                  style={{ cursor: "pointer" }}
                >
                  Learn More
                </a>
              </div>
            </div>

            {/* Accordion */}
            <div className="accordion-wrp">
              <ul className="accordion">
                {accordionData.map((item, index) => (
                  <li key={index}>
                    <a
                      className={`toggle ${
                        activeIndex === index ? "active-accordion" : ""
                      }`}
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        toggleAccordion(index);
                      }}
                    >
                      {item.title}
                    </a>
                    <p
                      className="inner"
                       
                    >
                      {item.content}
                    </p>
                  </li>
                ))}
              </ul>
            </div>

            <div className="our-support-wrp spacer">
              <h3>Still have questions?</h3>
              <p>
                Connect with Our Support Team for Personalized Assistance and
                Guidance!
              </p>
              <ul>
                <li className="btn-1">
                  <a href="/" aria-label="Chat with Us">
                    Chat with Us
                  </a>
                </li>
                <li className="btn-2">
                  <a
                    href="tel:+1-888-730-3338"
                    aria-label="Call us on +1-888-730-3338"
                  >
                    Call us on +1-888-730-3338
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
