import React from "react";
import CIPA from '../../../../assets/images/cipa.webp';
import ShopperApproved from '../../../../assets/images/shopper-approved.webp';
import IPABC from '../../../../assets/images/ipabc.webp';

const Trusted_pharmacy = () => {
    return(
        <>
<section class="truted-phamacy-sec spacer curve_bg extra-padding"> 
   <div class="container">
      <div class="row">
	    <div class="col-md-12">
		  <div class="trusted-pharcy-wrp">
		    <p>Trusted Pharmacy Partners: A Network of Accredited Providers Ensuring Your Health and Well-being</p>
		  </div>
		  <div class="trusted-pharmacy">
		    <ul>
			  <li>
			    <a href="/">
			    <figure role="none">
				  <img src={CIPA} alt="CIPA" width="160" height="84"/>
				</figure>
				</a>
			  </li>
			  <li>
			   <a href="/">
			    <figure role="none">
				  <img src={ShopperApproved} alt="Review" width="200" height="68"/>
				</figure>
				</a>
			  </li>
			  <li>
			  <a href="/">
			    <figure role="none">
				  <img src={IPABC} alt="IPACB" width="160" height="95"/>
				</figure>
				</a>
			  </li>
			</ul>
		  </div>
		</div>
	  </div>
   </div>
</section>
        </>
    )
}

export default Trusted_pharmacy;