import React, { useEffect, useRef } from "react";

const Reviews = () => {
  const widgetRef = useRef(null);

  useEffect(() => {
 
    const saLoadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.type = "text/javascript";
      document.head.appendChild(script);
    };

 
    if (!window.shopper_first) {
      saLoadScript("https://www.shopperapproved.com/widgets/testimonial/3.0/1912.js");
      window.shopper_first = true;
    }
  }, []);  

  return (
    <section className="reviews-sec extra-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="reviews-inner-wrp">
              <div
                ref={widgetRef}
                style={{
                  overflow: "hidden",
                  maxHeight: "164px",
                  minHeight: "164px",
                }}
                className="shopperapproved_widget sa_rotate sa_count3 sa_horizontal sa_bgWhite sa_colorBlack sa_borderGray sa_rounded sa_jMy sa_fixed sa_showlinks sa_large sa_showdate"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
