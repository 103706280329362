import React from "react";

const Customers = () => {
    return(
        <>
        <section className="contact-us-sec spacer">
  <div className="container">
    <div className="row">
	  <div className="col-md-4">
	    <div className="contact-title-wrp">
		  <h2>Convenient Online Pharmacy for US Customers</h2>
		</div>
	  </div>
	  <div className="col-md-8">
	    <div className="contact-info-wrp">
		  <p>Reach out to our customer service if you want to order your medications over the phone.</p>
		  <ul>
		    <li className="btn-1">
			  <a href="/contact/" aria-label="Contact us">Contact us</a>
			</li>
			<li className="btn-2">
			  <a href="tel:+1-888-730-3338" aria-label="Call us on +1-888-730-3338">Call us on +1-888-730-3338</a>
			</li>
		  </ul>
		</div>
	  </div>
	</div>
  </div>
</section>
        </>
    )
}

export default Customers;