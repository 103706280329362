import React from "react";
import BCBusiness from '../../../../assets/images/home-bc-business.webp';
import BusinessVancouver from '../../../../assets/images/home-business-vancouser.webp';
import VancouverSun from '../../../../assets/images/home-vancouser.webp';
import TheProvince from '../../../../assets/images/home-the-province.webp';

const SeenOn = () => {
    return(
        <>
        <section className="seen-on-sec spacer">
   <div className="container">
    <div className="desktop-version">
     <div className="row">
	   <div className="col-md-4">
	     <div className="seen-on-inner-wrp">
		   <h3>As Seen On</h3>
		 </div>
	   </div>
	   <div className="col-md-4">
	     <div className="seen-on-img-wrp">
		    <figure role="none">
			 <a href="/blog/press-features/bc-business/">
		     <img src={BCBusiness} alt="BC Business" />
			 </a>
			</figure>
			<figure role="none">
			<a href="/blog/press-features/business-vancouver/">
		     <img src={BusinessVancouver} alt="vancouver"  />
			 </a>
			</figure>
		 </div>
	   </div>
	   <div className="col-md-4">
	     <div className="seen-on-img-wrp seen-on-img-wrp-2">
		   <figure role="none">
		    <a href="/blog/press-features/business-in-vancouver/">
		     <img src={VancouverSun} alt="Business Vencover The Province"/>
			</a>
			</figure>
			<figure role="none">
			<a href="/blog/press-features/the-province/">
		     <img src={TheProvince} alt="The Province"/>
			 </a>
			</figure>
		 </div>
	   </div>
	 </div>
	 </div>
	 {/*Mobile Design*/}
	 <div className="mob-seen-on-sec">
	 <div className="row">
	   <div className="col-md-4">
	     <div className="seen-on-inner-wrp">
		   <h3>As Seen On</h3>
		 </div>
	   </div>
	   <div className="col-md-4">
	     <div className="seen-on-img-wrp seen-on-img-wrp-1">
		    <figure role="none">
			 <a href="/blog/press-features/bc-business/">
		     <img src={BCBusiness} alt="BC Business" />
			 </a>
			</figure>
			<figure role="none">
		    <a href="/blog/press-features/business-in-vancouver/">
		     <img src={BusinessVancouver} alt="Business Vencover The Province"/>
			</a>
			</figure>
		 </div>
	   </div>
	   <div className="col-md-4">
	     <div className="seen-on-img-wrp seen-on-img-wrp-2">
		 <figure role="none">
			<a href="/blog/press-features/business-vancouver/">
		     <img src={VancouverSun} alt="vancouver"  />
			 </a>
			</figure>
		   
			<figure role="none">
			<a href="/blog/press-features/the-province/">
		     <img src={TheProvince} alt="The Province"/>
			 </a>
			</figure>
		 </div>
	   </div>
	 </div>
	 </div>
   </div>
</section>
        </>
    )
}

export default SeenOn;