import React from "react";
import OrderMedication from '../../../../assets/images/order-medication-img.webp';

const Convenient = () => {
    return(
        <>
        <section className="order-medication-sec spacer curve_bg">
  <div className="container">
    <div className="row">
	
	  <div className="col-md-6">
	  <div className="order-med-inner-wrp">
	  <div className="order-medication-cont-wrp">
	    <p>Convenient</p>
		<h2>Order Medications Online and Enjoy Fast Delivery</h2>
		<p>At our online Canadian pharmacy, we make ordering medications easy and hassle-free. With our fast delivery service, you can get the medications you need without leaving your home.</p>
	  </div>
	  <div className="order-med-points-sec">
	  <div className="row">
	    <div className="col-md-6">
		<div className="order-medication-points">
		  <h3>Easy Process</h3>
		  <p>Browse our wide selection of medications and add them to your cart.</p>
		</div>
		</div>
		<div className="col-md-6">
		<div className="order-medication-points">
		  <h3>Fast Delivery</h3>
		  <p>We ship medications directly to your doorstep, ensuring quick and reliable delivery.</p>
		</div>
		</div>
	  </div>
	  </div>
	 
	  
	  <div className="learn-more-wrp">
	    <a href="/" aria-label="Learn More">Learn More</a>
	  </div>
	  </div>
	   </div>
	  <div className="col-md-6">
	    <div className="order-med-img-wrp">
		  <figure role="none">
		    <img src={OrderMedication} width="538" height="559" alt="Order Medication" />
		  </figure>
		</div>
	  </div>
	  
	</div>
  </div>
</section>
        </>
    )
}

export default Convenient;