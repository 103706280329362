import React, {useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import Logo from '../../../assets/images/logo-1.webp';
import UserIcon from '../../../assets/images/user-icon.svg';
import CartIcon from '../../../assets/images/cart-icon.webp';

const HeaderMenu = () => {
   const [isSticky, setIsSticky] = useState(false);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const stickyThreshold = 100;
        if (scrollPosition > stickyThreshold) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);


  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check if user data is in localStorage on initial load
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      setIsLoggedIn(true);
    }
  }, []);

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('userData');
    setIsLoggedIn(false);
    window.location.reload();
  };

  //Active Menu
  const [isActive, setIsActive] = useState(false);

  const toogleMenu = (e) => {
      e.preventDefault();
      setIsActive(!isActive); // Toggle active state
  };


    return(
        <>
        <header className={`header-sec ${isSticky ? 'sticky' : ''}`} id="header-sec">
           <div class="container">
              <div class="row">
                 <div class="col-md-2 col-lg-2">
                    <div class="header-left-wrp">
                       {/*Mobile Menu Icon Start*/}
                       <a href="/" class="open-menu" onClick={toogleMenu}>
                       <span class="line"></span>
                       <span class="line"></span>
                       <span class="line"></span>
                       </a>
                       {/*Mobile Menu Icon End*/}
                       {/*Desktop Logo Start*/}
                       <div class="logo-wrp">
                        <Link to="/">
                          <figure role="none">
                             <img src={Logo} alt="Online Canadian pharmacy" />
                          </figure>
                          </Link>
                       </div>
                       {/*Desktop Logo End*/}
                       {/*Mobile Cart Icon Start*/}
                       <div class="mob-cart-wrp">
                          <a href="/cart/">
                             <figure role="none">
                                <img src={CartIcon} alt="Cart" />
                                <figcaption class="mob-cart-item-count">
                                </figcaption>
                             </figure>
                          </a>
                       </div>
                       {/*Mobile Cart Icon End*/}
                    </div>
                 </div>
                 <div class="col-md-5 col-lg-5">
                    <div className={`header-menu-wrp ${isActive ? "active-menu" : ""}`}>
                       {/*Mobile Logo Start*/}
                       <div class="mobile-version-logo">
                          <figure role="none">
                           <img src={Logo} alt="Logo" />
                          </figure>
                          <div class="close-menu-wrp">
                             <a href="/" onClick={toogleMenu}>
                             <i class="fa-solid fa-xmark"></i>
                             </a>
                          </div>
                       </div>
                       {/*Mobile Logo End*/}
                       {/*Header Menu Start*/}
                       <div class="menu-header-menu-container">
                          <ul id="menu-header-menu" class="header-menu">
                             <li id="menu-item-266162" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-266162">
                                <a href="#">Products</a>
                                <ul class="sub-menu">
                                   <li id="menu-item-266164" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-266164">
                                    <Link to="prescription-drugs/">Prescription Drugs</Link></li>
                                   <li id="menu-item-266109" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-266109">
                                    <Link to="over-the-counter-drugs/">Over The Counter Drugs</Link></li>
                                   <li id="menu-item-266165" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-266165">
                                    <Link to="pet-medications/">Pet Medications</Link></li>
                                   <li id="menu-item-266135" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-266135">
                                    <Link to="all-drugs/">All Drugs</Link></li>
                                </ul>
                             </li>
                             <li id="menu-item-266133" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-266133">
                              <Link to="how-to-order/">How to Order</Link></li>
                             <li id="menu-item-266134" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-266134">
                              <Link to="about-us/">About Us</Link></li>
                             <li id="menu-item-266095" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-266095">
                              <Link to="faq/">FAQ’s</Link></li>
                             <li id="menu-item-266098" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-266098">
                              <Link to="contact/">Contact</Link></li>
                          </ul>
                       </div>
                       {/*Header Menu End*/}
                       {/*Mobile Top bar Start*/}
                       <div class="mob-version">
                       </div>
                       {/*Mobile Top bar End*/}
                    </div>
                 </div>
                 <div class="col-md-5 col-lg-5">
                    <ul class="user-wrp	">
                       {/*Search Widget Start*/}
                       <li class="seach-icon">
                          <div class="search-bar-wrp">
                            <form action="" method="POST">
                                <input type="text" placeholder="Search for your prescription medication" value=""/>
                            </form>
                          </div>
                       </li>
                       {/*Search Widget End*/}
                       {!isLoggedIn ? (
                       <li class="user-icon">
                          <Link to="my-account">
                             <figure role="none">
                                <img src={UserIcon} alt="User" width="24" height="24" />
                                <figcaption>
                                   Login
                                </figcaption>
                             </figure>
                          </Link>
                          <div class="tool-tip-box">
                             <Link to="my-account">Sign In / Register</Link>
                          </div>
                       </li>
                       ):(
                        <li class="user-icon logged-in-user">
                          <p>
                             <figure role="none">
                                <img src={UserIcon} alt="User" width="24" height="24" />
                                <figcaption>
                                   Account
                                </figcaption>
                             </figure>
                          </p>
                          <div class="tool-tip-box">
                           <ul>
                              <li>
                                 <Link to="my-account">Dashboard</Link>
                              </li>
                              <li>
                                 <button onClick={handleLogout} className="logout-btn">Logout</button>
                              </li>
                           </ul>
                          </div>
                       </li>
                       )}
                       <li class="cart-icon">
                          <a href="/cart/">
                             <figure role="none">
                                <img src={CartIcon} alt="Cart" width="24" height="26" />
                                <figcaption class="cart-item-count">
                                   0
                                </figcaption>
                             </figure>
                          </a>
                          <div class="tool-tip-box cart-tool-tip">
                             <ul>
                                <li class="cart-tt-header">
                                   <span>0 Item</span>
                                   <span><a href="https://ocp.sl.dev.itwellness.ca/cart/">VIEW CART</a></span>
                                </li>
                                <li class="cart-item">
                                   <p class="item-not-found">No items in cart.</p>
                                   <table>
                                      <tbody>
                                      </tbody>
                                   </table>
                                </li>
                             </ul>
                          </div>
                       </li>
                    </ul>
                 </div>
              </div>
              {/*Mobile Search Widget Start*/}
              <div class="mob-search-widget">
                 <div class="row">
                    <div class="col-md-12">
                       <div class="mob-search-inner-wrp">
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </header>
        </>
    )
}

export default HeaderMenu;