import React from "react";

const Cart = () => {
 
    return(
        <>
        {/*<iframe
      src="https://themefantasy.com/sitemaker/cart/?hideHeaderFooter=true"
      style={{ width: '100%', height: '100vh', border: 'none', header:'display:none' }}
      title="Dynamic Content Iframe"
    ></iframe>*/}
        </>
    )
}

export default Cart;