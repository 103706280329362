import React from "react";
import PopularPrescriptionData from '../Json/PopularPrescription';

const PopularPrescription = () => {
    return(
        <>
<section class="popular-prescription-sec spacer">
  <div class="container">
    <div class="row">
	  <div class="col-md-12">
	    <div class="popular-prescription-wrp">
		  <h2 class="common-title">Popular Prescription Medications</h2>
		  <ul class="popular-prescription mt-5">
          {PopularPrescriptionData.map((ppItem, index) => (
  <li key={index}>
    <a href={`/product/${ppItem.product_title.toLowerCase().replace(/\s+/g, '-')}/`}>
      {ppItem.product_title}
    </a>
  </li>
))}
		  </ul>
		</div>
	  </div>
	</div>
  </div>
</section>
        </>
    )
}

export default PopularPrescription;