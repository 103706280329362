import React from "react";
import './Prescription.css';
import SearchPrescription from "./Components/Search-prescription";
import PreferredPharmacy from "./Components/Preferred-Pharmacy";
import PopularPrescription from './Components/Popular-prescription';
import ViewPrescription from './Components/View-prescription';

const PrescriptionDrugs = () => {
    return(
        <>
        <SearchPrescription />
        <PopularPrescription />
        <PreferredPharmacy />
        <ViewPrescription />
        </>
    )
}

export default PrescriptionDrugs;