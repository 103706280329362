const ReasonData = [
    {
        'icon':require('../../../../assets/images/icon-1.webp'),
        'title':"The best customer service you can find online"
    },
    {
        'icon':require('../../../../assets/images/icon-2.webp'),
        'title':"Savings up to 70%"
    },
    {
        'icon':require('../../../../assets/images/icon-3.webp'),
        'title':"Low Canadian pharmacy prices"
    },
    {
        'icon':require('../../../../assets/images/icon-4.webp'),
        'title':"5-star Canadian pharmacy reviews from verified customers"
    },
    {
        'icon':require('../../../../assets/images/icon-5.webp'),
        'title':"Affordable pet medications from Canada"
    },
    {
        'icon':require('../../../../assets/images/icon-6.webp'),
        'title':"Affordable generic alternatives"
    }
]

export default ReasonData;