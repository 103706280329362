import React from "react";

const FAQ = () => {
    return(
        <>
        <h1>FAQ's</h1>
        </>
    )
}

export default FAQ;