import React, {useState, useEffect } from "react";

const Login = () => {
    const [loginData, setLoginData] = useState({ username: "", password: "" });
    const [message, setMessage] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Track if the user is logged in
    const [loading, setLoading] = useState(false);
    // Check if there's a token or user data in localStorage on initial load
    useEffect(() => {
      const userData = localStorage.getItem("userData");
      if (userData) {
        setIsLoggedIn(true); // User is logged in
      }
    }, []);
  
    // Handle form input changes
    const handleChange = (e) => {
      setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };
  
    // Handle login form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true); // Show loader
      try {
        const response = await fetch("https://themefantasy.com/sitemaker/wp-json/custom/v1/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: loginData.username,
            password: loginData.password,
          }),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          // Save user data and token in localStorage
          const userSession = {
            user_id: data.user_id,
            username: data.username,
            email: data.email,
          };
          localStorage.setItem("userData", JSON.stringify(userSession));
          setIsLoggedIn(true);
          window.location.reload();
          setMessage("Login successful!");
        } else {
          setMessage(data.message || "Failed to login.");
        }
      } catch (error) {
        setMessage("An error occurred.");
        console.error("Error:", error);
      } finally {
        setLoading(false); // Hide loader
      }
    };
  
    // Handle logout
    const handleLogout = () => {
      
      localStorage.removeItem("userData"); // Remove user session data
      setIsLoggedIn(false); // Update login status
      setMessage("Logged out successfully");
      window.location.reload();
    };
  

    return(
        <>
         
        {loading && (
  <div className="loader-wrp">
    <p>Logging in...</p>
  </div>
)}
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <p>
          <label>Username</label>
            <input type="text" name="username" placeholder="Username" value={loginData.username} onChange={handleChange} required/>
          </p>
          <p>
          <label>Password</label>
            <input type="password" name="password" placeholder="Password" value={loginData.password} onChange={handleChange} required />
          </p>
          <button type="submit" className="primary-btn">Login</button>
          <p>{message}</p>
        </form>
        </>
    )
}

export default Login;