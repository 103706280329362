import React, {useState, useEffect} from "react";
import RegistrationForm from './Templates/Template-registration';
import LoginForm from './Templates/Template-login';
import './My-Account.css';
import Dashboard from './Templates/Dashboard';

const MyAccount = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check if user data is in localStorage on initial load
  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      setIsLoggedIn(true);
    }
  }, []);

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('userData');
    setIsLoggedIn(false);
    window.location.reload();
  };


    return(
        <>
        {!isLoggedIn ? (
            <div className="my-account-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-wrp" id="login-form">
                            <LoginForm />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrp" id="registration-form">
                            <RegistrationForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        ):(
        <>
            <Dashboard />
        </>
        )}
        
        </>
    )
}

export default MyAccount;