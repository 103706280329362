import React from "react";

const About_Us = () => {
    return(
        <>
        <h1>About Us</h1>
        </>
    )
}

export default About_Us;