import React from "react";
import RightIcon from '../../../../assets/images/points-icon-2.webp';

const Banner = () => {
    return(
        <>
<div className="banner-sec">
   <div className="container">
     <div className="row">
	    <div className="col-md-6">
		   <div className="banner-cont-wrp">
		     <h1>Save with Online Canadian Pharmacy</h1>
			 <section>
			 <p>Online Canadian Pharmacy is a trusted and most popular CIPA-certified pharmacy from Canada that helps you save up to 70% on prescription drugs compared to regular prices. </p>
			 <div className="banner-search-widget">
			     
			 </div>
			 <div className="banner-points-wrp">
			   <ul>
			     <li><span><figure role="none"><img src={RightIcon} alt="Affordable" /></figure></span><span>Affordable.</span></li>
				 <li><span><figure role="none"><img src={RightIcon} alt="Trusted" /></figure></span><span>Trusted.</span></li>
				 <li><span><figure role="none"><img src={RightIcon} alt="Discreet" /></figure></span><span>Discreet.</span></li>
			   </ul>
			 </div>
			 </section>
		   </div>
		</div>
		<div className="col-md-6">
		<div className="banner-img-wrp">
		  
		  </div>
		</div>
		</div>
	 </div>
   </div>
 
        </>
    )
}

export default Banner;