import React from "react";

const Pet_Medication = () => {
    return(
        <>
        <h1>Pet Medication</h1>
        </>
    )
}

export default Pet_Medication;