import React from "react";

const Topbar = () => {
    return(
        <>
        <div class="top-bar-sec">
  <div class="container">
    <div class="row">
	  <div class="col-md-9">
	    <div class="topbar-cont-wrp">
		  <ul>
		    <li>
			  <span>Toll Free Phone:</span>
			  <span><a href="tel:+1-888-730-3338">+1-888-730-3338</a></span>
			</li>
			<li>
			  <span>Email:</span>
			  <span><a href="mailto:info@onlinecanadianpharmacy.com">info@onlinecanadianpharmacy.com</a></span>
			</li>
		  </ul>
		</div>
	  </div>
	  <div class="col-md-3">
	    <div class="topbat-btn-wrp">
		 
		</div>
	  </div>
	</div>
  </div>
</div>
        </>
    )
}

export default Topbar;