import React from "react";
import './css/Home.css';
import Banner from './Components/Banner';
import Reason from './Components/Reason';
import Popular_Prescription from './Components/Popular-prescription';
import TrustedPharmacy from './Components/Trusted-pharmacy';
import Registerpharmacy from './Components/Register-pharmacy';
import Reviews from './Components/Reviews';
import ShopperApproved from './Components/ShopperApproved';
import Customers from './Components/Customers';
import OrderMedication from './Components/Convenient';
import FAQ from './Components/FAQ';
import AboutPharmacy from './Components/About-Pharmacy';
import SeenOn from './Components/Seen-On';

const Home = () => {
    return(
        <>
        <Banner />
        <Reason />
        <Popular_Prescription />
        <TrustedPharmacy />
        <Registerpharmacy />
        <Reviews />
        <ShopperApproved />
        <Customers />
        <OrderMedication />
        <FAQ />
        <AboutPharmacy />
        <SeenOn />
        </>
    )
}

export default Home;