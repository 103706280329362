import React from "react";

const HowToOrder = () => {
    return(
        <>
        <h1>How To Order</h1>
        </>
    )
}

export default HowToOrder;