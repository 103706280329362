import React from "react";

const Copyright = () => {
    return(
        <>
        <div class="copyright-sec">
  <div class="container">
    <div class="row">
	  <div class="col-md-8">
	    <div class="copyright-cont-wrp">
		  <p>© Copyright 2024 All Rights Reserved.</p>
		  <p>All trademarks and registered trademarks appearing on this website are the property of their respective owners and onlinecanadianpharmacy.com is not affiliated with them in any way.</p>
		</div>
	  </div>
	  <div class="col-md-4">
	  <div class="t-and-c-wrp">
    <p>
        <a href="/">Privacy Policy</a>
        <a href="/">Terms &amp; Conditions</a>
    </p>
</div>
	</div>
	</div>
  </div>
</div>
        </>
    )
}

export default Copyright;