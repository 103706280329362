import React from "react";

const Contact = () => {
    return(
        <>
        <h1>Contact Us</h1>
        </>
    )
}

export default Contact;