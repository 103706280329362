import React, { useEffect } from "react";

const ShopperApproved = () => {
  useEffect(() => {
    const saLoadScript = (src) => {
      const script = document.createElement("script");
      script.src = src;
      script.type = "text/javascript";
      document.head.appendChild(script);
    };

    saLoadScript("https://www.shopperapproved.com/merchant/1912.js");

    window.sa_review_count = 20;
    window.sa_date_format = "F j, Y";
  }, []);

  return (
    <section className="shopper-approved-sec spacer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
              <div id="shopper_review_page">
                <div
                  id="review_header"
                  style={{ textAlign: "center", border: "0" }}
                >
                  &nbsp;
                </div>
                <div id="review_image" style={{ height: 0 }}>
                  <a
                    href="https://www.shopperapproved.com/reviews/onlinecanadianpharmacy.com"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <img
                      className="sa_logo"
                      src="//www.shopperapproved.com/widgets/images/widgetfooter-darklogo-eng.png"
                      alt="Shopper Approved"
                    />
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopperApproved;
