import React from "react";
import ReasonData from '../json/Reason';

const Reason = () => {
    return(
        <>
        <div className="reason-tbcp-sec spacer curve_bg">
  <div className="container">
    <div className="row">
	  <div className="col-md-12">
	    <div className="six-reasons">
		  <h2 className="text-center">6 reasons to buy from Canadian pharmacy</h2>
	    </div>
	  </div>
	  
	  <div className="row">
        {ReasonData.map((item, index) => (
            <div className="col-md-4">
		   <div className="reason-inner-wrp">
		     <figure role="none">
			   <img src={item.icon} alt={item.title} />
			 </figure>
			 <p>{item.title}</p>
		   </div>
		</div>
        ))}
	    
	  </div>
	  
	  
	</div>
  </div>
</div>
 
        </>
    )
}

export default Reason;