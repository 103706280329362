import React from "react";

const ContactUs = () => {
    return(
        <>
        <form action="" method="POST">
            <p>
                <label>Email</label>
                <input type="email" placeholder="Enter your email" />
            </p>
            <p>
                <label>Your Comments or Questions</label>
                <input type="email" placeholder="Enter your email" />
            </p>
            <small>This site is protected by reCAPTCHA and the Google.</small>
            <p>
                <input type="submit" value="Submit" className="submit-btn"/>
            </p>
        </form>
        </>
    )
}

export default ContactUs;