import React from "react";
import ContactForm from './Contact-Us';

const FooterMenu = () => {
    return(
<>
<div class="site-footer spacer">
    <div class="container">
	  <div class="row">
	    <div class="col-md-3">
		  <div class="footer-logo-wrp">
		    <figure role="none">
			  
			</figure>
			<p>At Online Canadian Pharmacy, we make your health and wellness our primary concern. We specialize in chronic care medicines taken to sustain and improve quality of life</p>
		  </div>
		</div>
		<div class="col-md-5">
		<div class="row">
		  <div class="col-md-6 col-6">
		    <div class="footer-menu">
			  <h3>Quick Links</h3>
			  <ul>
				<li>
					<a href="/">How to Order</a>
				</li>
				<li>
					<a href="/">Pet Medication</a>
				</li>
				<li>
					<a href="/">Non Prescription Drugs</a>
				</li>
				<li>
					<a href="/">Over The Counter Drugs</a>
				</li>
				<li>
					<a href="/">All Drugs</a>
				</li>
				<li>
					<a href="/">Health Tips</a>
				</li>
				<li>
					<a href="/">FAQ's</a>
				</li>
				<li>
					<a href="/">Contact</a>
				</li>
			  </ul>
			</div>
		  </div>
		  
		   <div class="col-md-6 col-6">
		    <div class="footer-menu">
			  <h3>Quick Links</h3>
			   <ul>
				<li>
					<a href="/">Dawm Polley</a>
				</li>
				<li>
					<a href="/">Press Features</a>
				</li>
				<li>
					<a href="/">Tell A Friend Promotion</a>
				</li>
				<li>
					<a href="/">Customer Agreement</a>
				</li>
				<li>
					<a href="/">Free Shipping Offer</a>
				</li>
				<li>
					<a href="/">Low Prices</a>
				</li>
				<li>
					<a href="/">privacy Policy</a>
				</li>
				<li>
					<a href="/">Terms and Conditions</a>
				</li>
			   </ul>
			</div>
		  </div>
		  
		  
		</div>
		    
		  </div>
		  
		  <div class="col-md-4">
		    <div class="footer-contact-wrp">
			  <h3>Contact Us</h3>
			    <ContactForm />
			</div>
		  </div>
	  </div>
	</div>
</div>

<div class="footer-contact-details-sec">
   <div class="container">
     <div class="row">
	   <div class="col-md-3">
	     <div class="footer-address-wrp">
		 <h4>Address</h4>
		 <p>Granville Web Services</p>
		 <address>Suite # 273 – 7360 <br/>137th Street Surrey,<br/> B.C. Canada V3W 1A3</address>
		 </div>
	   </div>
	   <div class="col-md-5">
	     <div class="row">
		   <div class="col-md-6 col-6">
		     <div class="contact-details-wrp">
			   <h4>Phone and Fax</h4>
			   <ul>
			     <li>
				 <span>Phone:</span>
				 <span><a href="tel:+1-888-730-3338">+1-888-730-3338</a></span>
				 </li>
				 <li>
				 <span>Fax Toll-Free:</span>
				 <span><a href="tel:1-888-530-5688">1-888-530-5688</a></span>
				 </li>
				 <li>
				 <span>International Phone:</span><br/>
				 <span><a href="tel:+1-604-598-4669">+1-604-598-4669</a></span>
				 </li>
				 <li>
				 <span>International Fax:</span><br/>
				 <span><a href="tel:+1-604-595-8976">+1-604-595-8976</a></span>
				 </li>
				 <li>
				 <span>Email:</span>
				 <span><a href="mailto:info@onlinecanadianpharmacy.com">info@onlinecanadianpharmacy.com</a></span>
				 </li>
			   </ul>
			 </div>
		   </div>
		    <div class="col-md-6 col-6">
		     <div class="contact-details-wrp">
			   <h4>Hours of Operation</h4>
			   <ul>
			     <li> 
				 <span>Monday - Friday:</span><br/>
				 <span>6.00 AM - 8.00 PM (PST)</span>
				 </li> 
				 <li>
				 <span>Saturday - Sunday:</span><br/>
				 <span>7.00 AM - 5.00 PM (PST)</span>
				 </li>
			   </ul>
			 </div>
		   </div>
		 </div>
	   </div>
	   <div class="col-md-4">
		     <div class="customer-care-wrp">
			   <p>For our Spanish speaking patients, you are invited to call +1-888-730-3338 and a Customer Care Specialist will be able to assist you with your order or with any questions you may have.</p>
			   <p>**Para servicio en español llame al +1-888-730-3338 y un representante le podra asistir con su orden.</p>
			 </div>
		   </div>
	 </div>
   </div>
</div>
        </>
    )
}

export default FooterMenu;