import React from "react";
import {Link} from 'react-router-dom';

const Popular_prescription = () => {
    return(
        <>
        <section className="popular-prescription-sec spacer">
  <div className="container">
	 <div className="row">
	  <div className="col-md-6">
	  
	    <div className="popular-prescription-wrp">
		 
		  <div className="mobile-title-wrp">
		     <h2>Popular Prescription Medications from Canada and beyond</h2>
		  </div>
		 
		  <ul>
		    <li>
			  <a href="/" aria-label="Eliquis">Eliquis</a>
			</li>
			<li>
			  <a href="/" aria-label="Relpax">Relpax</a>
			</li>
			<li>
			  <a href="/" aria-label="Cialis">Cialis</a>
			</li>
			<li>
			  <a href="/" aria-label="Viagra">Viagra</a>
			</li>
			<li>
			  <a href="/" aria-label="Valtrex">Valtrex</a>
			</li>
			<li>
			  <a href="/" aria-label="Advair Diskus">Advair Diskus</a>
			</li>
		  </ul>
		</div>
	  </div>
	  <div className="col-md-6">
	    <div className="pp-cont-wrp">
		 <h2>Popular Prescription Medications from Canada and beyond</h2>
		 <Link to="prescription-drugs" className="primary-btn" aria-label="Find your prescription medication">Find your prescription medication</Link>
		</div>
	  </div>
	</div>
  </div>
</section>
        </>
    )
}

export default Popular_prescription;