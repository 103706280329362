import React, { useEffect, useState } from 'react';
import FilterIcon from '../../../../assets/images/filter-icon.webp';
import ProductData from '../Json/product-data.json';
const ViewPrescription = () => {
	const alpha = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
	const [filteredProducts, setFilteredProducts] = useState({});
	const [searchQuery, setSearchQuery] = useState('');
  
	useEffect(() => {
	  let tempFilteredProducts = {};

	  alpha.forEach(letter => {
		const filtered = ProductData.filter(product => 
		  product.A2.charAt(0).toUpperCase() === letter &&
		  !product.A3.includes('non Rx')
		);
  
		if (filtered.length > 0) {
		  tempFilteredProducts[letter] = filtered;
		}
	  });

	  setFilteredProducts(tempFilteredProducts);
	}, []); 
  
	const handleSearch = (e) => {
	  const query = e.target.value.toLowerCase();
	  setSearchQuery(query);
	};
  
	const filteredAndSearchedProducts = () => {
	  let tempFilteredProducts = {};

	  alpha.forEach(letter => {
		const filtered = ProductData.filter(product => 
		  product.A2.toLowerCase().includes(searchQuery) && 
		  product.A2.charAt(0).toUpperCase() === letter &&
		  !product.A3.includes('non Rx')
		);
  
		if (filtered.length > 0) {
		  tempFilteredProducts[letter] = filtered;
		}
	  });
  
	  return tempFilteredProducts;
	};
  
	const displayFilteredProducts = searchQuery.length > 0 ? filteredAndSearchedProducts() : filteredProducts;
  
	const [isSticky, setIsSticky] = useState(false);

	useEffect(() => {
	  const observer = new IntersectionObserver(
		(entries) => {
		  const isInViewport = entries.some((entry) => entry.isIntersecting);
		  setIsSticky(isInViewport);
		},
		{
		  root: null,
		  threshold: 0, // Trigger when any part of the target is in view
		}
	  );
  
	  // Target the element you want to observe
	  const viewPrescriptionDrugsSec = document.querySelector('.vp-body');
	  
	  if (viewPrescriptionDrugsSec) {
		observer.observe(viewPrescriptionDrugsSec);
	  }
  
	  // Cleanup the observer when the component unmounts
	  return () => {
		if (viewPrescriptionDrugsSec) {
		  observer.unobserve(viewPrescriptionDrugsSec);
		}
	  };
	}, []);
  
    return(
        <>
        <section class="view-prescription-drugs-sec spacer">
<div class="filter-inner-wrp">
  <div class="container">
    <div class="row">
	  <div class="col-md-12">
	    <div class="view-prescription-wrp">
		  <div  className={`vp-header ${isSticky ? 'vp-sticky' : ''}`}>
		    <h2>View Prescription Drugs Alphabetically</h2>
			<div class="vp-innder-header-wrp">
			  <div class="filter-alpha-wrp">
			    <h3 class="filter-alphabet">A</h3>
			  </div>
			  <div class="alphabet-wrp">
			    <ul class="alphanav">
				  <li>
				    <a href="#A" class="active-alpha">A</a>
				  </li>
				  <li>
				    <a href="#B">B</a>
				  </li>
				  <li>
				    <a href="#C">C</a>
				  </li>
				  <li>
				    <a href="#D">D</a>
				  </li>
				  <li>
				    <a href="#E">E</a>
				  </li>
				  <li>
				    <a href="#F">F</a>
				  </li>
				  <li>
				    <a href="#G">G</a>
				  </li>
				  <li>
				    <a href="#H">H</a>
				  </li>
				  <li>
				    <a href="#I">I</a>
				  </li>
				  <li>
				    <a href="#J">J</a>
				  </li>
				  <li>
				    <a href="#K">K</a>
				  </li>
				  <li>
				    <a href="#L">L</a>
				  </li>
				  <li>
				    <a href="#M">M</a>
				  </li>
				  <li>
				    <a href="#N">N</a>
				  </li>
				  <li>
				    <a href="#O">O</a>
				  </li>
				  <li>
				    <a href="#P">P</a>
				  </li>
				  <li>
				    <a href="#Q">Q</a>
				  </li>
				  <li>
				    <a href="#R">R</a>
				  </li>
				  <li>
				    <a href="#S">S</a>
				  </li>
				  <li>
				    <a href="#T">T</a>
				  </li>
				  <li>
				    <a href="#U">U</a>
				  </li>
				  <li>
				    <a href="#V">V</a>
				  </li>
				  <li>
				    <a href="#W">W</a>
				  </li>
				  <li>
				    <a href="#X">X</a>
				  </li>
				  <li>
				    <a href="#Y">Y</a>
				  </li>
				  <li>
				    <a href="#Z">Z</a>
				  </li>
				</ul>
			  </div>
			</div>
			
		  </div>
		</div>
	  </div>
	</div>
  </div>
</div>
  
  <div class="container">
    <div class="row">
	  <div class="col-md-12">
	    <div class="vp-body">
		    <div class="vp-search-filter">
			  <div class="flter-icon-wrp">
			    <figure role="none">
				  <img src={FilterIcon} width="30" height="21" alt="Filter" />
				</figure>
			  </div>
			  <form className="search-brands-wrp">
        <input 
          type="text" 
          placeholder="Filter by medication name" 
          className="search-brands-box" 
          id="searchinput" 
          onChange={handleSearch} 
          value={searchQuery}
        />
      </form>
			</div>
			{alpha.map(letter => (
            displayFilteredProducts[letter] && displayFilteredProducts[letter].length > 0 && (
              <div key={letter}>
                <h2 id={letter}>{letter}</h2>
                <ul className={`grouping group_${letter}`}>
                  {displayFilteredProducts[letter].map(product => (
                    <li key={product.C7} className="item">
                      <p className="asl_content">
                        <a 
                          className="asl_res_url" 
                          href={`/product/${product.C7}/`} 
                          title={`Buy ${product.A2} online`}
                        >
                          {product.A2}
                        </a>
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            )
          ))}
		  </div>
	  </div>
	</div>
  </div>
</section>
 
        </>
    )
}

export default ViewPrescription;