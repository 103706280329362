const PopularPrescription = [
    {
        'product_title': 'Estring'
    },
    {
        'product_title': 'Synthroid'
    },
    {
        'product_title': 'Premarin Vaginal Cream'
    },
    {
        'product_title': 'Spiriva'
    },
    {
        'product_title': 'Cialis'
    },
    {
        'product_title': 'Combivent Respimat'
    },
    {
        'product_title': 'Vagifem Vaginal Tablet'
    },
    {
        'product_title': 'Asacol HD'
    },
    {
        'product_title': 'Advair Diskus'
    },
    {
        'product_title': 'Xarelto'
    },
    {
        'product_title': 'Actigall'
    },
    {
        'product_title': 'Levitra'
    },
    {
        'product_title': 'Celebrex'
    },
    {
        'product_title': 'Lumigan Eyedrops'
    },
    {
        'product_title': 'Premarin'
    },
    {
        'product_title': 'Wellbutrin XL'
    },
    {
        'product_title': 'Entocort EC'
    },
    {
        'product_title': 'Viagra'
    } 
]

export default PopularPrescription;