import React from "react";
import DawnPolley from '../../../../assets/images/Dawn-Polley.webp';

const RegisterPharmacy = () => {
    return(
        <>
<section className="licensed-pharmacy-sec spacer curve_bg extra-padding">
  <div className="container">
  <div className="licensed-pharmacy-wrp">
    <div className="row">
	  <div className="col-md-6">
	    <div className="licensed-cont-wrp">
		  <p>Registered Pharmacist & Founder</p>
		  <h2>Hello! I am Dawn Polley<span>B.Sc.Phm.</span></h2>
		  <ul>
		    <li>Founder of OnlineCanadianPharmacy.com</li>
			<li>Leader in the online Canadian pharmacy industry for over 25 years.</li>
			<li>Pioneer online pharmacist, who was instrumental in setting up industry safety standards.</li>
			<li>Past President of Canadian International Pharmacy Association (CIPA).</li>
		  </ul>
		  <div className="learn-more-wrp">
		  <a href="/dawn-polley/">Learn More</a> 
		  </div>
		</div>
	  </div>
	  <div className="col-md-6">
	    <div className="licensed-pharmacy-img-wrp">
          <figure role="none">
		    <img src={DawnPolley} alt="Dawn Polley." width="400" height="416" />
		  </figure>
		  </div>
		</div>
	  </div>
	  </div>
	</div>
 
</section>
        </>
    )
}

export default RegisterPharmacy;