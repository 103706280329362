import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Components/Pages/Home-includes/Template-home';
import AboutUS from './Components/Pages/About-includes/Template-about';
import Page_404 from './Components/Pages/Page_404/Template_404';
import HowToOrder from './Components/Pages/How-to-order/Template-hto';
import FAQ from './Components/Pages/FAQ-includes/Template-faq';
import Contact from './Components/Pages/Contact-includes/Template-contact';
import PrescriptionDrugs from './Components/Pages/Prescription-drugs-includes/Template-prescription-drugs';
import OverTheCounter from './Components/Pages/otc-includes/Template-OTC';
import PetMedication from './Components/Pages/Pet-Medication-includes/Template-Pet-Medication';
import AllDrugs from './Components/Pages/All-Drugs-includes/Template-all-drugs';
import MyAccount from './Components/Pages/My-Account/Template-my-account';
import Cart from './Components/Pages/Cart-includes/Template-cart';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<AboutUS />} />
          <Route path="how-to-order" element={<HowToOrder />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="contact" element={<Contact />} />
          <Route path="prescription-drugs" element={<PrescriptionDrugs />} />
          <Route path="Over-the-counter-drugs" element={<OverTheCounter />} />
          <Route path="pet-medications" element={<PetMedication />} />
          <Route path="all-drugs" element={<AllDrugs />} />
          <Route path="my-account" element={<MyAccount />} />
          <Route path="cart" element={<Cart />} />
          <Route path="*" element={<Page_404 />} />
          
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
