import React, {useState, useEffect} from 'react';
import Tab from '../../Woocommerce/Tab';

const Dashboard = () => {
    const [userData, setUserData] = useState(null);

    useEffect(() => {
      // Get user data from localStorage when the component loads
      const storedData = localStorage.getItem("userData"); // Assuming the key is 'userData'
      if (storedData) {
        try {
          const parsedData = JSON.parse(storedData); // Parse the JSON string
          setUserData(parsedData);
        } catch (error) {
          console.error("Error parsing user data from localStorage:", error);
        }
      }
    }, []); // Run only once on component mount
  
    return(
        <>
         {userData ? (
        <div className="dashboard-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="dashboard-inner-wrp">
                            <h1>My Account</h1>
                            <p>Hello {userData.username}</p>
                            <p>You can manage your orders and profile from here.</p>
                            <Tab />
                        </div>
                    </div>
                </div>
            </div>
        </div>
         ) : (
            <>
            </>
         )}
        </>
    )
}

export default Dashboard;