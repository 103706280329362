import React, { useState } from "react";

const RegisterUser = () => {
    const [formData, setFormData] = useState({ username: "", email: "", password: "" });
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true); // Show loader
      try {
        const response = await fetch("https://themefantasy.com/sitemaker/wp-json/custom/v1/create-user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: formData.username,
            email: formData.email,
            password: formData.password,
          }),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          setMessage("User created successfully!");
        } else {
          setMessage(data.message || "Failed to create user.");
        }
      } catch (error) {
        console.error("Error:", error);
        setMessage("An error occurred.");
      } finally {
        setLoading(false); // Hide loader
      }
    };
      
  return (
    <div>
       {loading && (
  <div className="loader-wrp">
    <p>Logging in...</p>
  </div>
)}
      <h2>Register User</h2>
      <form onSubmit={handleSubmit}>
        <p>
          <label>Username</label>
          <input type="text" name="username" placeholder="Username" value={formData.username} onChange={handleChange} required />
        </p>
        <p>
        <label>Email</label>
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
        </p>
        <p>
          <label>Password</label>
          <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
        </p>
        <button type="submit" className="primary-btn">Register</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default RegisterUser;
